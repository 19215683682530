*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background-color: black;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.25;
}

.Stage {
  position: relative;

  &__next {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.Grid {
  display: flex;

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__cell {
    position: relative;
    transition-property: transform;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
  }
}

.Log {
  $self: &;
  $line: calc(1em * 1.25);

  position: fixed;
  top: 0;
  left: 0.5em;
  z-index: 1;
  color: #eee;
  border-radius: 1px;
  font-size: max(1.33vh, 1vw, 0.66rem);
  letter-spacing: 0.03em;
  mask-image: linear-gradient(
    to top,
    rgb(0, 0, 0) 0%,
    rgb(0, 0, 0) 33%,
    transparent 100%
  );

  &--in {
    #{$self}__entries {
      transition-property: transform;
      transform: translateY(
        -(calc($line * 1))
      ); // Transform up one complete line
    }
  }

  &:empty {
    display: none;
  }

  &__equation {
    display: flex;
  }

  &__symbol,
  &__equals {
    width: 3ch;
    text-align: center;
  }
}
