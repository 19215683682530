*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background-color: black;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.25;
}

.Stage {
  position: relative;
}

.Stage__next {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.Grid {
  display: flex;
}

.Grid__column {
  display: flex;
  flex-direction: column;
}

.Grid__cell {
  position: relative;
  transition-property: transform;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}

.Log {
  position: fixed;
  top: 0;
  left: 0.5em;
  z-index: 1;
  color: #eee;
  border-radius: 1px;
  font-size: max(1.33vh, 1vw, 0.66rem);
  letter-spacing: 0.03em;
  -webkit-mask-image: linear-gradient(to top, black 0%, black 33%, transparent 100%);
          mask-image: linear-gradient(to top, black 0%, black 33%, transparent 100%);
}

.Log--in .Log__entries {
  transition-property: transform;
  transform: translateY(-1.25em);
}

.Log:empty {
  display: none;
}

.Log__equation {
  display: flex;
}

.Log__symbol, .Log__equals {
  width: 3ch;
  text-align: center;
}
/*# sourceMappingURL=index.add3679f.css.map */
